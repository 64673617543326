import styled from "@xstyled/styled-components";
import { theme } from "../../theme/theme";
import TableRow from "../atoms/Table/TableRow";
import { BackgroundColorProps, BoxShadowProps } from "../../theme/types";
import { FunctionalComponent, ChildrenProps } from "../../types";

export interface ProductTableRowProps
  extends ChildrenProps,
    BackgroundColorProps,
    BoxShadowProps {}

const Tr = styled(TableRow)({
  "> td": {
    borderTop: 1,
    borderStyle: "solid",
    borderColor: theme.colors["neutral-100"],
    paddingTop: theme.space[2],
    paddingBottom: theme.space[2],
    paddingRight: theme.space[4],
    "&:first-child": {
      paddingLeft: theme.space[4],
    },
  },
});
const ProductTableRow: FunctionalComponent<ProductTableRowProps> = (props) => {
  return <Tr {...props} />;
};

export default ProductTableRow;
