import styled from "@xstyled/styled-components";
import { theme } from "../../theme/theme";
import TableHeaderRow from "../atoms/Table/TableHeaderRow";
import { FunctionalComponent, ChildrenProps } from "../../types";

interface ProductTableHeaderRowProps extends ChildrenProps {}

const Tr = styled(TableHeaderRow)({
  "> th": {
    paddingTop: theme.space[1],
    paddingBottom: theme.space[3],
    paddingRight: theme.space[4],
    "&:first-child": {
      paddingLeft: theme.space[4],
    },
  },
});
const ProductTableHeaderRow: FunctionalComponent<ProductTableHeaderRowProps> = (
  props
) => {
  return <Tr {...props} />;
};

export default ProductTableHeaderRow;
