import { forwardRef } from "react";
import { BorderRadiusProps, HeightProps, WidthProps } from "../../theme/types";
import { x } from "@xstyled/styled-components";

export interface SkeletonProps
  extends WidthProps,
    HeightProps,
    BorderRadiusProps {}

const Div = x.div;
const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>((props, ref) => {
  return (
    <Div ref={ref} animation="pulse" backgroundColor="neutral-100" {...props} />
  );
});

export default Skeleton;
