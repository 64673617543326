import { createContext, useContext, useState } from "react";

import Resume from "../../components/templates/Resume";
import Analysis from "../../components/templates/PurchaseAnalysis/Analysis";
import EfficientPurchasing from "../../components/templates/EfficientPurchasing/EfficientPurchasing";
import { AnalyticsApi } from "../../models/analitycsApi";

interface ReorderItems {
  resumen: (keyof AnalyticsApi.GetAnalytics.SummaryValues)[];
  analisis: string[];
  compra: string[];
  mainComponents: React.ReactNode[];
}

interface WidgetVisibility {
  total_purchased: boolean;
  buyed_vs_efficient: boolean;
  medicinal_total: boolean;
  non_medicinal_total: boolean;
  orders: boolean;
  prices_variation: boolean;

  resumen: boolean;
  compraEficiente: boolean;
  analisis: boolean;
  distrib_purchase_per_dm: boolean;
  distrib_purchase_meds_and_not_meds: boolean;
  money_per_dm: boolean;
  top_n_meds: boolean;
  top_n_no_meds: boolean;
  compra_efectuada_vs_eficiente: boolean;
  real_and_orders_purchase_per_dm: boolean;
  real_and_orders_purchase_per_pos: boolean;
}
interface ReorderContextValue {
  initialItems: ReorderItems;
  items: ReorderItems;
  visibility: WidgetVisibility;
  isReordering: boolean;
  toggleWidgetVisibility: (key: keyof WidgetVisibility) => void;
  setIsReordering: (value: boolean) => void;
  startReorder: (key: keyof ReorderItems, currentItems: any[]) => void;
  updateItems: (key: keyof ReorderItems, updatedItems: any[]) => void;
  saveChanges: () => void;
  cancelChanges: () => void;
}

const ReorderContext = createContext<ReorderContextValue | undefined>(
  undefined
);

export const ReorderProvider = ({ children }) => {
  const initialWidgetVisibility: WidgetVisibility = {
    total_purchased: true,
    buyed_vs_efficient: true,
    medicinal_total: true,
    non_medicinal_total: true,
    orders: true,
    prices_variation: true,
    resumen: true,
    compraEficiente: true,
    analisis: true,
    distrib_purchase_per_dm: true,
    distrib_purchase_meds_and_not_meds: true,
    money_per_dm: true,
    top_n_meds: true,
    top_n_no_meds: true,
    compra_efectuada_vs_eficiente: true,
    real_and_orders_purchase_per_dm: true,
    real_and_orders_purchase_per_pos: true,
  };

  const [initialItems, setInitialItems] = useState<ReorderItems>({
    resumen: [],
    analisis: ["first", "second", "third", "fourth", "fifth"],
    compra: ["first", "second", "third"],
    mainComponents: [<Resume />, <Analysis />, <EfficientPurchasing />],
  });
  const [items, setItems] = useState<ReorderItems>({
    resumen: [],
    analisis: ["first", "second", "third", "fourth", "fifth"],
    compra: ["first", "second", "third"],
    mainComponents: [<Resume />, <Analysis />, <EfficientPurchasing />],
  });

  const [initialVisibility, setInitialVisibility] = useState<WidgetVisibility>(
    initialWidgetVisibility
  );
  const [visibility, setVisibility] = useState<WidgetVisibility>(
    initialWidgetVisibility
  );
  const [isReordering, setIsReordering] = useState(false);

  const startReorder = (key, currentItems) => {
    setInitialItems((prev) => ({ ...prev, [key]: currentItems }));
    setItems((prev) => ({ ...prev, [key]: currentItems }));
  };

  const updateItems = (key, updatedItems) => {
    setItems((prev) => ({ ...prev, [key]: updatedItems }));
  };
  const toggleWidgetVisibility = (key: keyof WidgetVisibility) => {
    setVisibility((prev) => ({ ...prev, [key]: !prev[key] }));
  };
  const saveChanges = () => {
    setInitialItems(items);
    setInitialVisibility(visibility);
    setIsReordering(false);
  };

  const cancelChanges = () => {
    setItems(initialItems);
    setVisibility(initialVisibility);
    setIsReordering(false);
  };
  const value: ReorderContextValue = {
    initialItems,
    items,
    visibility,
    isReordering,
    toggleWidgetVisibility,
    setIsReordering,
    startReorder,
    updateItems,
    saveChanges,
    cancelChanges,
  };
  return (
    <ReorderContext.Provider value={value}>{children}</ReorderContext.Provider>
  );
};
export const useReorderProvider = () => {
  const context = useContext(ReorderContext);
  if (!context) {
    throw new Error("useDataProvider must be used within a DataProvider");
  }
  return context;
};
