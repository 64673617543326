import { SortableKnob } from "react-easy-sort";
import {
  ChartContainer,
  WidgetHeaderSubContainer,
} from "../PurchaseAnalysis/Analysis";
import {
  WidgetDescription,
  WidgetTitle,
  WidgetTitleContainer,
} from "../Resume";
import { analisisValues, message } from "../../../utils/formats";
import { DataMessage } from "./EfficientPurchasing";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useDataProvider } from "../../../context/provider";
import { Box, Button, Icon } from "../../../extendeal-components";
export default function POSOrdersAndTotalAmount() {
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { state } = useDataProvider();

  const posIdNames = state.purchaseByPos?.map(
    (item) => item.point_of_sale_name
  );
  const totalPurchase = state.purchaseByPos?.map((item) => item.total);
  const totalOrders = state.purchaseByPos?.map((item) => item.num_orders);
  return (
    <>
      <WidgetHeaderSubContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <WidgetTitleContainer>
          {isReordering && (
            <SortableKnob>
              <Icon as="drag" color="neutral-600" />
            </SortableKnob>
          )}
          <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
            <Icon as="storeFront" type="outline" color="neutral-800" />
          </Box>
          <WidgetTitle>
            {analisisValues.real_and_orders_purchase_per_pos.title}
          </WidgetTitle>
        </WidgetTitleContainer>
        {isReordering && (
          <Box>
            {" "}
            {visibility.real_and_orders_purchase_per_pos ? (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("real_and_orders_purchase_per_pos")
                }
              >
                <Icon as="eye" type="outline" />
              </Button>
            ) : (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("real_and_orders_purchase_per_pos")
                }
              >
                <Icon as="eyeOff" />
              </Button>
            )}
          </Box>
        )}
      </WidgetHeaderSubContainer>
      <WidgetDescription>
        {analisisValues.real_and_orders_purchase_per_pos.description}
      </WidgetDescription>
      <ChartContainer>
        {state.purchaseByPos?.length ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: "",
              },
              xAxis: {
                categories: posIdNames,
                crosshair: true,
                accessibility: {
                  description: "",
                },
                gridLineWidth: 1,
                gridLineColor: "#e0e0e0",
              },
              yAxis: [
                {
                  title: {
                    text: "Compra efectuada",
                    style: {
                      color: "#333333",
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                  gridLineDashStyle: "Dot",
                },
                {
                  title: {
                    text: "Ordenes de compra",
                    style: {
                      color: "#333333",
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                  opposite: true,
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                  gridLineDashStyle: "Dot",
                },
              ],
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: "Compra efectuada",
                  data: totalPurchase,
                  yAxis: 0,
                  color: "#002DAD",
                },
                {
                  name: "Ordenes de compra",
                  data: totalOrders,
                  type: "line",
                  yAxis: 1,
                  color: "#0094FF",
                },
              ],
              credits: {
                enabled: false,
              },
            }}
          />
        ) : (
          <DataMessage>{message.no_data}</DataMessage>
        )}
      </ChartContainer>
    </>
  );
}
