import { HTMLAttributes } from "react";
import { x } from "@xstyled/styled-components";
import { BackgroundColorProps, BoxShadowProps } from "../../../theme/types";
import { FunctionalComponent, ChildrenProps } from "../../../types";

type HTMLTrProps = Pick<HTMLAttributes<HTMLTableRowElement>, "onClick">;
export interface TableRowProps
  extends ChildrenProps,
    BackgroundColorProps,
    BoxShadowProps,
    HTMLTrProps {}

const Tr = x.tr;
const TableRow: FunctionalComponent<TableRowProps> = (props) => {
  return <Tr {...props} cursor={props.onClick ? "pointer" : undefined} />;
};

export default TableRow;
