import { x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";

interface TableBodyProps extends ChildrenProps {}

const Tbody = x.tbody;
const TableBody: FunctionalComponent<TableBodyProps> = (props) => {
  return <Tbody {...props} />;
};

export default TableBody;
