import { x } from "@xstyled/styled-components";
import {
  PaddingProps,
  MarginProps,
  BoxShadowProps,
  BorderRadiusProps,
  WidthProps,
  BordersProps,
  HeightProps,
  BackgroundColorProps,
} from "../../theme/types";
import { ChildrenProps } from "../../types";
import { forwardRef } from "react";

export interface BoxProps
  extends ChildrenProps,
    PaddingProps,
    MarginProps,
    BoxShadowProps,
    BorderRadiusProps,
    BordersProps,
    WidthProps,
    HeightProps,
    BackgroundColorProps {}

const Div = x.div;
const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return <Div ref={ref} {...props} />;
});

export default Box;
