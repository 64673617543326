import { USER_ACCESS_COOKIE_ID, USER_AUTH_COOKIE_ID } from "../constants/index";
import useCookie from "./useCookie";

interface UseUserAuthReturn {
  token?: string;
  accessToken?: string;
  logout: () => void;
  isAuthenticated: boolean;
  saveUserToken: (token: string, expiration: number) => void;
  saveAccessToken: (token: string, expiration: number) => void;
}

const checkAuth = (): boolean => {
  const token = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${USER_AUTH_COOKIE_ID}=`));
  return !!token;
};

const useUserAuth = (): UseUserAuthReturn => {
  const [userTokenId, updateTokenId, deleteTokenId] = useCookie(
    USER_AUTH_COOKIE_ID,
    ""
  );
  const [accessToken, updateAccessToken] = useCookie(USER_ACCESS_COOKIE_ID, "");

  return {
    token: userTokenId,
    accessToken,
    isAuthenticated: checkAuth(),
    logout: deleteTokenId,
    saveUserToken: (token: string, expiration: number) =>
      updateTokenId(token, {
        seconds: expiration,
      }),
    saveAccessToken: (token: string, expiration: number) =>
      updateAccessToken(token, {
        seconds: expiration,
      }),
  };
};

export default useUserAuth;
