import { SortableKnob } from "react-easy-sort";
import { useReorderProvider } from "../../../context/ReorderContext/provider";

import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume";
import { analisisValues, message } from "../../../utils/formats";
import {
  ChartContainer,
  WidgetHeaderContainer,
  WidgetHeaderSubContainer,
} from "./Analysis";
import { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import { useDataProvider } from "../../../context/provider";
import { Box, Button, Icon } from "../../../extendeal-components";

export default function Top20MedicinalProducts() {
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { state } = useDataProvider();
  const [selectedPOSId, setSelectedPOSId] = useState("all");
  const [selectedPOSName, setSelectedPOSName] = useState("Todos");
  const [selectedData, setSelectedData] = useState({
    description: [],
    quantity: [],
  });

  const handleFilterChange = (pos) => {
    if (pos === "Todos") {
      setSelectedPOSId("all");
      setSelectedPOSName("Todos");
    } else {
      setSelectedPOSId(pos.id);
      setSelectedPOSName(pos.name);
    }
  };
  const medicinalCategories = state.medicinalTop20?.map(
    (value) => value.description
  );
  const medicinalQuantity = state.medicinalTop20?.map(
    (value) => value.quantity
  );

  return (
    <>
      <WidgetHeaderContainer>
        <WidgetHeaderSubContainer>
          <WidgetTitleContainer>
            {isReordering && (
              <SortableKnob>
                <Icon as="drag" color="neutral-600" />
              </SortableKnob>
            )}
            <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
              <Icon as="pharmacy" type="outline" color="neutral-800" />
            </Box>
            <WidgetTitle>{analisisValues.top_n_meds.title}</WidgetTitle>
          </WidgetTitleContainer>

          <WidgetDescription>
            {analisisValues.top_n_meds.description}
          </WidgetDescription>
        </WidgetHeaderSubContainer>
        <WidgetHeaderSubContainer style={{ display: "flex" }}>
          {isReordering && (
            <Box>
              {" "}
              {visibility.top_n_meds ? (
                <Button
                  backgroundColor={"neutral-white"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("top_n_meds")}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"neutral-white"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("top_n_meds")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          )}
        </WidgetHeaderSubContainer>
      </WidgetHeaderContainer>
      <ChartContainer>
        {state.medicinalTop20?.length ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "bar",
              },
              title: {
                text: "",
              },
              xAxis: {
                categories: medicinalCategories,
                gridLineWidth: 1,
                gridLineColor: "#e0e0e0",
                gridLineDashStyle: "Dot",
              },
              yAxis: {
                title: {
                  text: "",
                },
                tickInterval: 1,
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: "Cantidad de productos",
                  data: medicinalQuantity,
                  color: "#0084FF",
                },
              ],
              credits: {
                enabled: false,
              },
            }}
          />
        ) : (
          <DataMessage>{message.no_data}</DataMessage>
        )}
      </ChartContainer>
    </>
  );
}
