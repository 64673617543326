import { useCallback } from "react";
import styled from "@emotion/styled";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../context/ReorderContext/provider";
import { Box } from "../../extendeal-components";

export default function Main() {
  const { items, updateItems, isReordering } = useReorderProvider();

  const onSortEnd = useCallback(
    (oldIndex, newIndex) => {
      const result = arrayMoveImmutable(
        items.mainComponents,
        oldIndex,
        newIndex
      );
      updateItems("mainComponents", result);
    },
    [items, updateItems]
  );
  return (
    <Box w={"100%"} px={"20"}>
      <Sortable onSortEnd={onSortEnd} allowDrag={!!isReordering}>
        {items?.mainComponents.map((item, index) => (
          <SortableItem key={index}>
            <Box>{item}</Box>
          </SortableItem>
        ))}
      </Sortable>
    </Box>
  );
}

const Sortable = styled(SortableList)`
  user-select: none;
`;
