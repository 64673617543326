import { defaultTheme } from "@xstyled/styled-components";

const theme = {
  ...defaultTheme,
  colors: {
    "neutral-50": "#F5F6FA",
    "neutral-100": "#E0E1E9",
    "neutral-400": "#40435B",
    "neutral-600": "#ACAEC4",
    "neutral-700": "#6B6F93",
    "neutral-800": "#40435B",
    "neutral-white": "#FFFFFF",
    "neutral-alpha": "#00000000",
    "neutral-alpha-500": "#ACAEC4A3",
    "neutral-alpha-700": "#6B6F93B8",
    "primary-10": "#EBF5FF",
    "primary-25": "#DCEDFF",
    "primary-50": "#BADBFF",
    "primary-500": "#84C0FE",
    "primary-600": "#0171E6",
    "primary-700": "#0062C8",
    "secondary-25": "#F0F5FF",
    "secondary-50": "#E3ECFF",
    "secondary-800": "#0B2033",
    "semantic-success-50": "#EBF9F3",
    "semantic-success-100": "#DDF6E2",
    "semantic-success-600": "#07AD4B",
    "semantic-success-700": "#157840",
    "semantic-success-800": "#146D3B",
    "semantic-error-50": "#FFEBEB",
    "semantic-error-600": "#E22E2E",
    "semantic-error-700": "#C62828",
    "semantic-warning-50": "#FFF9E6",
    "semantic-warning-600": "#FFC700",
    "semantic-warning-700": "#9F7100",
    "semantic-information-50": "#F2F7FF",
    "semantic-information-100": "#C6D9FE",
    "discount-50": "#FFECE0",
    "discount-600": "#FF6300",
    "information-600": "#1A66FC",
  },
};

export { theme };
