import Overlay, { OverlayProps } from "./components/Overlay";
import { HeightProps, PositionProps, WidthProps } from "../../../theme/types";
import { createGlobalStyle, x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";

export interface ModalProps
  extends ChildrenProps,
    PositionProps,
    WidthProps,
    HeightProps {
  isOpen?: boolean;
  onOverlayClick?: OverlayProps["onClick"];
}
const Div = x.div;
const GlobalOverflowHiddenStyle = createGlobalStyle({
  body: {
    overflow: "hidden",
  },
});
const Modal: FunctionalComponent<ModalProps> = (props) => {
  const { onOverlayClick, isOpen, ...rest } = props;

  if (!isOpen) return null;

  return (
    <Overlay onClick={onOverlayClick}>
      <GlobalOverflowHiddenStyle />
      <Div
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -75%)"
        zIndex={100}
        {...rest}
      />
    </Overlay>
  );
};

export default Modal;
