import { PaddingProps, WidthProps } from "../../../theme/types";
import { CursorProps, x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";
import { forwardRef, TdHTMLAttributes } from "react";

type HTMLTdProps = Pick<TdHTMLAttributes<HTMLTableCellElement>, "onClick">;

export interface TableCellProps
  extends ChildrenProps,
    PaddingProps,
    Pick<WidthProps, "maxWidth">,
    HTMLTdProps,
    CursorProps {}

const Td = x.td;
const TableCell: FunctionalComponent<TableCellProps> = forwardRef<
  HTMLTableCellElement,
  TableCellProps
>((props, ref) => {
  return <Td ref={ref} {...props} />;
});

export default TableCell;
