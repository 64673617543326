import { IconType } from "react-icons";
import {
  MdSearch,
  MdOutlineSearch,
  MdArrowForward,
  MdOutlineArrowForward,
  MdCloudUpload,
  MdOutlineCloudUpload,
  MdDashboard,
  MdOutlineDashboard,
  MdPaid,
  MdOutlinePaid,
  MdGroup,
  MdOutlineGroup,
  MdInventory,
  MdOutlineInventory,
  MdSell,
  MdOutlineSell,
  MdVisibility,
  MdOutlineVisibility,
  MdVisibilityOff,
  MdOutlineVisibilityOff,
  MdEdit,
  MdOutlineEdit,
  MdMoreVert,
  MdOutlineMoreVert,
  MdPublic,
  MdOutlinePublic,
  MdAdd,
  MdOutlineAdd,
  MdPauseCircle,
  MdOutlinePauseCircle,
  MdPercent,
  MdOutlinePercent,
  MdClose,
  MdOutlineClose,
  MdCheck,
  MdOutlineCheck,
  MdChevronRight,
  MdOutlineChevronRight,
  MdInfo,
  MdInfoOutline,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdTrendingUp,
  MdOutlineTrendingUp,
  MdTrendingDown,
  MdOutlineTrendingDown,
  MdArrowDropDown,
  MdArrowDropUp,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdStorefront,
  MdOutlineStorefront,
  MdAccountCircle,
  MdOutlineAccountCircle,
  MdAttachMoney,
  MdOutlineAttachMoney,
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineKeyboardArrowUp,
  MdHelp,
  MdHelpOutline,
  MdOpenInNew,
  MdOutlineOpenInNew,
  MdError,
  MdErrorOutline,
  MdEmail,
  MdOutlineMailOutline,
  MdPowerOff,
  MdOutlinePowerOff,
  MdDownload,
  MdOutlineDownload,
  MdWarning,
  MdOutlineWarningAmber,
  MdReceiptLong,
  MdOutlineReceiptLong,
  MdDragHandle,
  MdOutlineDragHandle,
  MdRemoveRedEye,
  MdOutlineRemoveRedEye,
  MdLocalPharmacy,
  MdOutlineLocalPharmacy,
  MdShoppingBag,
  MdOutlineShoppingBag,
} from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";
import { FaTrashAlt, FaRegTrashAlt } from "react-icons/fa";

const filled = {
  search: MdSearch,
  arrowForward: MdArrowForward,
  cloudUpload: MdCloudUpload,
  dashboard: MdDashboard,
  sales: MdPaid,
  clients: MdGroup,
  inventory: MdInventory,
  products: MdSell,
  visibility: MdVisibility,
  visibilityOff: MdVisibilityOff,
  trash: FaTrashAlt,
  edit: MdEdit,
  verticalMenu: MdMoreVert,
  world: MdPublic,
  add: MdAdd,
  pause: MdPauseCircle,
  percent: MdPercent,
  close: MdClose,
  check: MdCheck,
  chevronRight: MdChevronRight,
  info: MdInfo,
  checkCircle: MdCheckCircle,
  trendingUp: MdTrendingUp,
  trendingDown: MdTrendingDown,
  arrowDropDown: MdArrowDropDown,
  arrowDropUp: MdArrowDropUp,
  storeFront: MdStorefront,
  accountCircle: MdAccountCircle,
  money: MdAttachMoney,
  arrowUp: MdKeyboardArrowUp,
  arrowDown: MdKeyboardArrowDown,
  help: MdHelp,
  openInNew: MdOpenInNew,
  error: MdError,
  email: MdEmail,
  powerOff: MdPowerOff,
  download: MdDownload,
  warning: MdWarning,
  receipt: MdReceiptLong,
  drag: MdDragHandle,
  eye: MdRemoveRedEye,
  eyeOff: IoMdEyeOff,
  pharmacy: MdLocalPharmacy,
  shoppingBag: MdShoppingBag,
};

const outline: Record<keyof typeof filled, IconType> = {
  search: MdOutlineSearch,
  arrowForward: MdOutlineArrowForward,
  cloudUpload: MdOutlineCloudUpload,
  dashboard: MdOutlineDashboard,
  sales: MdOutlinePaid,
  clients: MdOutlineGroup,
  inventory: MdOutlineInventory,
  products: MdOutlineSell,
  visibility: MdOutlineVisibility,
  visibilityOff: MdOutlineVisibilityOff,
  trash: FaRegTrashAlt,
  edit: MdOutlineEdit,
  verticalMenu: MdOutlineMoreVert,
  world: MdOutlinePublic,
  add: MdOutlineAdd,
  pause: MdOutlinePauseCircle,
  percent: MdOutlinePercent,
  close: MdOutlineClose,
  check: MdOutlineCheck,
  chevronRight: MdOutlineChevronRight,
  info: MdInfoOutline,
  checkCircle: MdCheckCircleOutline,
  trendingUp: MdOutlineTrendingUp,
  trendingDown: MdOutlineTrendingDown,
  arrowDropDown: MdOutlineArrowDropDown,
  arrowDropUp: MdOutlineArrowDropUp,
  storeFront: MdOutlineStorefront,
  accountCircle: MdOutlineAccountCircle,
  money: MdOutlineAttachMoney,
  arrowUp: MdOutlineKeyboardArrowUp,
  arrowDown: MdOutlineKeyboardArrowDown,
  help: MdHelpOutline,
  openInNew: MdOutlineOpenInNew,
  error: MdErrorOutline,
  email: MdOutlineMailOutline,
  powerOff: MdOutlinePowerOff,
  download: MdOutlineDownload,
  warning: MdOutlineWarningAmber,
  receipt: MdOutlineReceiptLong,
  drag: MdOutlineDragHandle,
  eye: MdOutlineRemoveRedEye,
  eyeOff: IoMdEyeOff,
  pharmacy: MdOutlineLocalPharmacy,
  shoppingBag: MdOutlineShoppingBag,
};

const icons = {
  filled,
  outline,
};

export { icons };
