import { PaddingProps, WidthProps } from "../../../theme/types";
import { x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";

export interface TableHeaderCellProps
  extends ChildrenProps,
    PaddingProps,
    Pick<WidthProps, "w"> {}

const Th = x.th;
const TableHeaderCell: FunctionalComponent<TableHeaderCellProps> = (props) => {
  return <Th {...props} />;
};

export default TableHeaderCell;
