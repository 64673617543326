import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { ColorTokens, SpacingTokens } from "../../../../theme/types";
import { StyleToastProps, ToastProps } from "../Toast";

type StylePropToToken = Partial<
  Record<keyof StyleToastProps, keyof typeof theme>
>;
const propToTokenCategoryMapping: StylePropToToken = {
  backgroundColor: "colors",
  color: "colors",
  padding: "space",
};

type StylePropsEntries = [keyof StyleToastProps, ColorTokens | SpacingTokens][];

const mapPropsToTheme = (props: Partial<StyleToastProps>) => {
  const propEntries = Object.entries(props) as StylePropsEntries;

  return propEntries.reduce((acc, [propName, propValue]) => {
    const tokenCategory = propToTokenCategoryMapping[propName];

    const mappedValue = tokenCategory
      ? theme[tokenCategory][
          propValue as keyof (typeof theme)[typeof tokenCategory]
        ]
      : propValue;

    return { ...acc, [propName]: mappedValue };
  }, {});
};

const forwardedProps: (keyof ToastProps)[] = ["children"];
const Container = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    forwardedProps.includes(prop as keyof ToastProps),
})<Partial<StyleToastProps>>((props) => ({
  ...mapPropsToTheme(props),
  width: "100%",
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  gap: theme.space[4],
  padding: theme.space[4],
  fontFamily: "'Source Sans 3', sans-serif",
  borderRadius: theme.radii.lg,
  fontSize: theme.fontSizes.sm,
  fontWeight: theme.fontWeights.normal,
}));

export default Container;
