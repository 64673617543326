import { theme } from "../../../theme/theme";
import { ColorTokens, FontSizeTokens } from "../../../theme/types";
import { FunctionalComponent } from "../../../types";
import { icons } from "./icons";

export interface IconProps {
  as: keyof typeof icons.filled;
  size?: FontSizeTokens;
  color?: ColorTokens;
  type?: "filled" | "outline";
}

const Icon: FunctionalComponent<IconProps> = (props) => {
  const { as, size = "xl", color = "neutral-700", type = "filled" } = props;
  const IconComponent = icons[type][as];

  return (
    <IconComponent
      fontSize={theme.fontSizes[size]}
      color={theme.colors[color]}
    />
  );
};

export default Icon;
