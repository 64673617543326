import { DOMAttributes } from "react";
import { createPortal } from "react-dom";
import { x } from "@xstyled/styled-components";
import { ChildrenProps, FunctionalComponent } from "../../../../types";

export interface OverlayProps extends ChildrenProps {
  onClick?: DOMAttributes<HTMLDivElement>["onClick"];
}

const Div = x.div;
const Overlay: FunctionalComponent<OverlayProps> = (props) => {
  return createPortal(
    <Div
      w="100%"
      h="100%"
      position="fixed"
      top={0}
      left={0}
      backgroundColor="neutral-alpha-700"
      zIndex={99}
      {...props}
    />,
    document.body
  );
};

export default Overlay;
