import { ReactNode } from "react";
import {
  ThemeProvider as XStyledThemeProvider,
  Preflight,
} from "@xstyled/styled-components";
import { theme } from "./theme";

interface ThemeProviderProps {
  children?: ReactNode;
}

const ThemeProvider = (props: ThemeProviderProps): JSX.Element => {
  const { children } = props;

  return <XStyledThemeProvider theme={theme}>{children}</XStyledThemeProvider>;
};

export { Preflight };
export default ThemeProvider;
