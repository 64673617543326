import { ReorderProvider } from "./context/ReorderContext/provider";
import { useEffect } from "react";
import { useDataProvider } from "./context/provider";

import Main from "./components/templates/Main";
import Searchbar from "./components/templates/Searchbar";
import { x } from "@xstyled/styled-components";
const navbarHeight = "60px";
function App() {
  const { getPointsOfSale, pointsOfSale } = useDataProvider();
  useEffect(() => {
    getPointsOfSale();
  }, []);
  return (
    <ReorderProvider>
      <x.div
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        minH={`calc(100vh - ${navbarHeight})`}
        backgroundColor={"#f0f5ff"}
      >
        <x.div w={"full"} maxW="1280px"></x.div>
        {pointsOfSale ? (
          <>
            <Searchbar />
            <Main />
          </>
        ) : null}
      </x.div>
    </ReorderProvider>
  );
}

export default App;
