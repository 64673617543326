import { theme } from "../../../theme/theme";
import { AreaChartComponents } from "./types";

const gridLines: AreaChartComponents.XAxisProps = {
  gridLineWidth: 1,
  gridLineColor: theme.colors["neutral-100"],
};

const axisCommonStyles: AreaChartComponents.XAxisProps = {
  title: { text: null },
  lineWidth: 0,
  tickWidth: 0,
  gridLineWidth: 0,
  labels: {
    useHTML: true,
    distance: 8,
    style: {
      color: theme.colors["neutral-700"],
      fontSize: theme.fontSizes.xs,
      fontWeight: theme.fontWeights.normal,
      textAlign: "center",
    },
  },
};

const xAxis: AreaChartComponents.XAxisProps = {
  ...axisCommonStyles,
};

const yAxis: AreaChartComponents.YAxisProps = {
  ...axisCommonStyles,
  ...gridLines,
};

const area: Highcharts.PlotAreaOptions = {
  lineColor: theme.colors["primary-500"],
  fillColor: {
    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
    stops: [
      [0, "rgba(132, 192, 254, 0.6)"],
      [1, "rgba(132, 192, 254, 0)"],
    ],
  },
  lineWidth: 2,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: true,
        fillColor: theme.colors["primary-600"],
        lineWidth: 1,
        lineColor: theme.colors["neutral-white"],
        radius: 4,
      },
    },
  },
};

const plotOptions: AreaChartComponents.PlotOptionsProps = {
  area,
  areaspline: area,
};

const exporting: AreaChartComponents.ExportingProps = { enabled: false };

const boost: AreaChartComponents.BoostProps = {
  enabled: true,
  useGPUTranslations: true,
};

const credits: AreaChartComponents.CreditsProps = { enabled: false };

const tooltip: AreaChartComponents.TooltipProps = {
  backgroundColor: theme.colors["secondary-800"],
  borderRadius: 4,
  useHTML: true,
  distance: 20,
};

const chart: AreaChartComponents.ChartProps = {
  spacing: [4, 0, 0, 0],
  backgroundColor: theme.colors["neutral-white"],
  allowMutatingData: false,
  displayErrors: false,
  plotBackgroundColor: theme.colors["neutral-white"],
  showAxes: true,
  width: null,
  style: {
    fontFamily: "'Source Sans 3', sans-serif",
  },
};

const title: AreaChartComponents.TitleProps = { text: undefined };
const legend: AreaChartComponents.LegendProps = { enabled: false };

const defaultOptions = {
  title,
  legend,
  chart,
  xAxis,
  yAxis,
  plotOptions,
  credits,
  exporting,
  tooltip,
  boost,
};

export { defaultOptions };
