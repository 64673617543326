import RSuiteAvatar, { AvatarProps as RSuiteAvatarProps } from "rsuite/Avatar";
// import "rsuite/Avatar/styles/index.css";
import { theme } from "../../theme/theme";
import { FunctionalComponent } from "../../types";
import styled from "styled-components";

export interface AvatarProps {
  name?: string;
  size?: RSuiteAvatarProps["size"];
}

const StyledAvatar = styled(RSuiteAvatar)({
  backgroundColor: theme.colors["primary-10"],
  color: theme.colors["primary-600"],
  fontWeight: theme.fontWeights.semibold,
  fontFamily: "'Source Sans 3', sans-serif",
});

const getInitials = (fullName?: string): string => {
  if (!fullName) return "";
  const words = fullName.split(" ");
  const initials = words
    .slice(0, 2)
    .map((word) => word.substring(0, 1).toUpperCase())
    .join("");

  return initials;
};

const Avatar: FunctionalComponent<AvatarProps> = (props) => {
  const { name, size = "sm" } = props;

  return <StyledAvatar size={size} circle alt={getInitials(name)} />;
};

export default Avatar;
