import { FunctionalComponent } from "../../types";
import RSuiteDateRangePicker, {
  DateRangePickerProps,
} from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import { createGlobalStyle } from "styled-components";
import { theme } from "../../theme/theme";

export interface DateRangeProps
  extends Pick<
    DateRangePickerProps,
    | "locale"
    | "ranges"
    | "caretAs"
    | "format"
    | "placeholder"
    | "character"
    | "shouldDisableDate"
    | "defaultOpen"
    | "onShortcutClick"
    | "onChange"
    | "value"
    | "size"
  > {}

const DateRangePicker: FunctionalComponent<DateRangeProps> = (props) => {
  return (
    <>
      <DateRangePickerGlobalStyles />
      <RSuiteDateRangePicker
        {...props}
        className="vendors-design-system-1"
        menuClassName="vendors-design-system-2"
      />
    </>
  );
};

export default DateRangePicker;

const DateRangePickerGlobalStyles = createGlobalStyle({
  ".vendors-design-system-1": {
    ".rs-input-group": {
      flexDirection: "row-reverse",
    },
    ".rs-btn-close": {
      padding: "10px 10px",
      position: "relative",
    },
  },
  ".vendors-design-system-2": {
    ".rs-picker-daterange-panel": {
      ".rs-btn-xs": {
        fontWeight: theme.fontWeights.bold,
      },
      ".rs-picker-daterange-header": {
        display: "none",
      },
      ".rs-picker-toggle-wrapper": {
        ".rs-picker-toggle.rs-btn": {
          paddingLeft: theme.space[8],
        },
      },
      ".rs-picker-toggle": {
        ".rs-picker-toggle-placeholder": {
          paddingLeft: theme.space[3],
        },
        ".rs-btn": {
          ".rs-picker-toggle-indicator": {
            ".rs-picker-caret-icon, .rs-picker-clean": {
              right: "420px",
              fontSize: theme.fontSizes.lg,
              color: theme.colors["neutral-600"],
            },
          },
        },
      },
      ".rs-icon": {
        color: "#acaec4",
      },
      ".rs-btn-close": {
        ".rs-icon": {
          verticalAlign: "bottom",
          left: "390px",
        },
      },
      ".rs-picker-daterange-predefined": {
        ".rs-btn": {
          border: "solid 0.5px #a1a2a4",
          margin: theme.space[1],
          borderRadius: theme.radii["2xl"],
          color: "#7e7f85",
        },
      },
      ".rs-stack-item": {
        ".rs-btn-sm:hover": {
          textDecoration: "none",
          background: theme.colors["primary-10"],
          color: theme.colors["primary-600"],
          border: "none",
        },
      },
      ".rs-btn": {
        "&.rs-btn-primary.rs-btn-sm": {
          borderRadius: theme.radii.lg,
          background: theme.colors["primary-600"],
          color: theme.colors["neutral-white"],
          border: "none",
        },
      },
      ".rs-picker-popup": {
        zIndex: 101,
        ".rs-calendar": {
          ".rs-calendar-table-cell-content": {
            borderRadius: "20px",
          },
        },
      },
      ".rs-picker-has-value": {
        ".rs-btn, .rs-picker-toggle": {
          ".rs-picker-toggle-value": {
            color: theme.colors["neutral-800"],
          },
        },
      },
      ".rs-picker-value-count": {
        borderRadius: theme.radii.full,
        border: `1px solid ${theme.colors["semantic-information-100"]}`,
        background: theme.colors["semantic-information-50"],
        color: theme.colors["neutral-800"],
      },
    },
  },
});
