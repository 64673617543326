import RSuiteTooltip from "rsuite/esm/Tooltip";
import { ChildrenProps } from "../../types";
import { forwardRef } from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme";
import "./tooltip.module.css";
export interface TooltipProps extends ChildrenProps {
  maxW?: string;
}

const StyledTooltip = styled(RSuiteTooltip)((props: TooltipProps) => ({
  paddingTop: theme.space[1],
  paddingBottom: theme.space[1],
  paddingRight: theme.space[4],
  paddingLeft: theme.space[4],
  fontFamily: "'Source Sans 3', sans-serif",
  color: theme.colors["neutral-white"],
  fontSize: theme.fontSizes.sm,
  fontWeight: theme.fontWeights.normal,
  lineHeight: theme.lineHeights.tight,
  maxW: props.maxW,
  backgroundColor: theme.colors["secondary-800"],
}));

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  return <StyledTooltip ref={ref} {...props} />;
});

export default Tooltip;
