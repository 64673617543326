import { x } from "@xstyled/styled-components";
import { HeightProps, WidthProps } from "../../theme/types";
import { ImgHTMLAttributes } from "react";
import { FunctionalComponent } from "../../types";

export interface ImageProps
  extends Pick<ImgHTMLAttributes<HTMLImageElement>, "src" | "alt">,
    WidthProps,
    HeightProps {}

const Img = x.img;
const Image: FunctionalComponent<ImageProps> = (props) => {
  return <Img {...props} />;
};

export default Image;
