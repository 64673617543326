import axios from "axios";
import { AnalyticsApi, AnalyticsType } from "../../models/analitycsApi";
import { useUserAuth } from "../../extendeal-components";
import { useNavigate } from "react-router-dom";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ANALYTICS_API}`,
});

const useAnalitycsApi = () => {
  const { token, isAuthenticated } = useUserAuth();
  // const navigate = useNavigate();
  const authorizationHeaders = { Authorization: `Bearer ${token}` };

  const getAnalitycs = async <
    EpName extends keyof AnalyticsApi.GetAnalytics.ResponsesMap
  >(
    payload: AnalyticsApi.GetAnalytics.Payload<EpName>
  ): Promise<AnalyticsApi.GetAnalytics.ResponsesMap[EpName] | undefined> => {
    try {
      const { data } = await instance.post<
        AnalyticsApi.GetAnalytics.ResponsesMap[EpName] | undefined
      >("/analytics/data", payload, { headers: authorizationHeaders });
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  const getPosId = async (): Promise<AnalyticsApi.GetPosId.Response> => {
    try {
      const { data } = await instance.get<AnalyticsApi.GetPosId.Response>(
        "/analytics/user_pos",
        {
          headers: authorizationHeaders,
        }
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  return {
    getAnalitycs,
    getPosId,
  };
};
export default useAnalitycsApi;
