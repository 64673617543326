import InputPicker, { InputPickerProps } from "rsuite/InputPicker";
// import "rsuite/InputPicker/styles/index.css";
import { styled } from "styled-components";
import { FunctionalComponent } from "../../types";
import { theme } from "../../theme/theme";

export interface SelectProps
  extends Pick<
    InputPickerProps,
    | "data"
    | "value"
    | "onChange"
    | "placeholder"
    | "open"
    | "onClose"
    | "onOpen"
    | "onChange"
    | "readOnly"
    | "disabled"
    | "renderMenuItem"
    | "renderValue"
    | "menuStyle"
    | "style"
    | "size"
    | "cleanable"
    | "menuAutoWidth"
  > {}

const Select: FunctionalComponent<SelectProps> = (props) => {
  return <StyledInputPicker size="sm" menuStyle={{ zIndex: 110 }} {...props} />;
};

export default Select;
const StyledInputPicker = styled(InputPicker)<{ disabled?: boolean }>`
  border-radius: 8px;
  /* Enable when the other Inputs share the same tokens */
  /* border: 1px solid ${theme.colors["neutral-700"]}; */
  background: ${theme.colors["neutral-white"]};
  font-family: "Source Sans 3", sans-serif;
  ${({ disabled }) =>
    disabled &&
    `
    border: 1px solid ${theme.colors["neutral-100"]};
    background: ${theme.colors["neutral-50"]};
  `}
`;
