import { x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";

interface TableHeaderRowProps extends ChildrenProps {}

const Tr = x.tr;
const TableHeaderRow: FunctionalComponent<TableHeaderRowProps> = (props) => {
  return <Tr {...props} />;
};

export default TableHeaderRow;
