import { useState } from "react";

type CookieOptions = {
  seconds?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: boolean;
};

enum CookieParameters {
  Expires = "expires",
  Domain = "domain",
  SameSite = "samesite",
  Path = "path",
  Secure = "secure",
}

type SetCookie = (name: string, value: string, options: CookieOptions) => void;

const ONE_HOUR_IN_SECONDS = 120;
const setCookie: SetCookie = (name, value, options): void => {
  const {
    seconds = ONE_HOUR_IN_SECONDS,
    secure = true,
    sameSite = true,
    path,
    domain,
  } = options;

  let cookieString = `${name}=${encodeURIComponent(value)}`;

  if (path) {
    cookieString += `; ${CookieParameters.Path}=${path}`;
  }

  if (seconds) {
    const expires = new Date(Date.now() + seconds * 1000).toUTCString();
    cookieString += `; ${CookieParameters.Expires}=${expires}`;
  }

  if (domain) {
    cookieString += `; ${CookieParameters.Domain}=${domain}`;
  }

  if (secure) {
    cookieString += `; ${CookieParameters.Secure}`;
  }

  if (sameSite) {
    cookieString += `; ${CookieParameters.SameSite}=strict`;
  }

  document.cookie = cookieString;
};

const getCookie = (cookieName: string): string =>
  document.cookie.split("; ").reduce((accumulator, currentCookie) => {
    const [name, value] = currentCookie.split("=");
    return name === cookieName ? decodeURIComponent(value || "") : accumulator;
  }, "");

type UseCookieReturn = [
  string | undefined,
  (value: string, options: CookieOptions) => void,
  () => void
];

const useCookie = (
  cookieName: string,
  initialValue?: string
): UseCookieReturn => {
  const [cookieValue, setCookieValue] = useState<string | undefined>(
    () => getCookie(cookieName) || initialValue
  );

  const updateCookie = (value: string, options: CookieOptions) => {
    setCookieValue(value);
    setCookie(cookieName, value, options);
  };

  const deleteCookie = () => {
    updateCookie("", {
      seconds: -1,
    });
    setCookieValue(undefined);
  };

  return [cookieValue, updateCookie, deleteCookie];
};

export default useCookie;
