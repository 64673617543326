import { x } from "@xstyled/styled-components";
import { FunctionalComponent, ChildrenProps } from "../../../types";

interface TableHeaderRowProps extends ChildrenProps {}

const Thead = x.thead;
const TableHeader: FunctionalComponent<TableHeaderRowProps> = (props) => {
  return <Thead {...props} />;
};

export default TableHeader;
