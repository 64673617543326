import { x } from "@xstyled/styled-components";
import { forwardRef } from "react";
import { OverflowProps, TypographyProps } from "../../theme/types";
import { ChildrenProps } from "../../types";

const tags = {
  p: x.p,
  span: x.span,
};

export interface TextProps
  extends ChildrenProps,
    TypographyProps,
    Pick<OverflowProps, "overflow"> {
  as?: keyof typeof tags;
}

const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const { as = "p", ...rest } = props;
  const TextComponent = tags[as];

  return (
    <TextComponent
      ref={ref}
      fontWeight="400"
      fontSize="default"
      color="neutral-800"
      fontFamily="'Source Sans 3', sans-serif"
      {...rest}
    />
  );
});

export default Text;
