import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SortableKnob } from "react-easy-sort";
import { analisisValues, message, colorPalette } from "../../../utils/formats";
import { ChartContainer } from "./Analysis";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useDataProvider } from "../../../context/provider";
import { styled } from "styled-components";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import { Box, Button, Icon } from "../../../extendeal-components";

export default function DrugstorePurchaseDistribution() {
  const { state } = useDataProvider();
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();

  const chartD = state.drugDistribution
    ? state.drugDistribution.map((value) => {
        return {
          name: value.drug_manufacturer_name,
          y: value.total,
        };
      })
    : null;

  return (
    <>
      <WidgetHeadContainer>
        <WidgetTitleContainer>
          {isReordering && (
            <SortableKnob>
              <Icon as="drag" color="neutral-600" />
            </SortableKnob>
          )}
          <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
            <Icon as="storeFront" type="outline" color="neutral-800" />
          </Box>
          <WidgetTitle>
            {analisisValues.distrib_purchase_per_dm.title}
          </WidgetTitle>
        </WidgetTitleContainer>
        {isReordering && (
          <Box>
            {" "}
            {visibility.distrib_purchase_per_dm ? (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("distrib_purchase_per_dm")
                }
              >
                <Icon as="eye" type="outline" />
              </Button>
            ) : (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("distrib_purchase_per_dm")
                }
              >
                <Icon as="eyeOff" />
              </Button>
            )}
          </Box>
        )}
      </WidgetHeadContainer>
      <WidgetDescription>
        {analisisValues.distrib_purchase_per_dm.description}
      </WidgetDescription>

      <ChartContainer>
        {state.drugDistribution?.length ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pie",
              },
              title: {
                text: "",
              },
              colors: colorPalette,
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y:,.0f}</b>",
              },
              plotOptions: {
                pie: {
                  innerSize: "70%",
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    format: "{point.name}: {point.percentage:.1f} %",
                  },
                },
              },
              credits: {
                enabled: false,
              },
              series: [
                {
                  data: chartD,
                },
              ],
            }}
          />
        ) : (
          <>
            <DataMessage>{message.no_data}</DataMessage>
          </>
        )}
      </ChartContainer>
    </>
  );
}
const WidgetHeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
