import {
  PaddingProps,
  FlexBoxProps,
  BackgroundColorProps,
  BorderRadiusProps,
  HeightProps,
  BordersProps,
} from "../../theme/types";
import { x } from "@xstyled/styled-components";
import { ChildrenProps } from "../../types";
import { forwardRef } from "react";

export interface StackProps
  extends ChildrenProps,
    PaddingProps,
    FlexBoxProps,
    BackgroundColorProps,
    BordersProps,
    BorderRadiusProps,
    HeightProps {}

const Div = x.div;
const Stack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  return <Div ref={ref} display="flex" {...props} />;
});

export default Stack;
