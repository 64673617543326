import { styled } from "@xstyled/styled-components";
import RSuiteLoader, { LoaderProps as RSuiteLoaderProps } from "rsuite/Loader";
// import "rsuite/Loader/styles/index.css";
import { theme } from "../../theme/theme";
import { ColorTokens } from "../../theme/types";

export interface LoaderProps extends Pick<RSuiteLoaderProps, "size"> {
  spinColor?: ColorTokens;
}
const StyledLoader = styled(RSuiteLoader)((props: LoaderProps) => ({
  ".rs-loader-spin::after": {
    "border-top-color": theme.colors[props.spinColor || "neutral-100"],
  },
}));
const Loader = (props: LoaderProps): JSX.Element => {
  return <StyledLoader {...props} />;
};

export default Loader;
