import { ButtonHTMLAttributes, forwardRef } from "react";
import { x } from "@xstyled/styled-components";
import {
  PaddingProps,
  BackgroundColorProps,
  TypographyProps,
  WidthProps,
  BorderRadiusProps,
  MarginProps,
  BordersProps,
  HeightProps,
} from "../../theme/types";
import { ChildrenProps } from "../../types";
import Icon, { IconProps } from "./Icon/Icon";
import Loader from "./Loader";
import { StackProps } from "./Stack";
import { TextProps } from "./Text";

type HTMLButtonProps = Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "type" | "disabled"
>;
export interface ButtonProps
  extends ChildrenProps,
    HTMLButtonProps,
    PaddingProps,
    BackgroundColorProps,
    TypographyProps,
    HeightProps,
    WidthProps,
    BorderRadiusProps,
    BordersProps,
    MarginProps,
    Pick<TextProps, "textDecoration">,
    Pick<StackProps, "gap" | "justifyContent"> {
  disabled?: boolean;
  isLoading?: boolean;
  /** @deprecated use <Icon /> component instead */
  leftIcon?: IconProps;
  /** @deprecated use <Icon /> component instead */
  rightIcon?: IconProps;
}

const ButtonComponent = x.button;
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    leftIcon,
    rightIcon: rightIconProp,
    isLoading,
    disabled,
    ...rest
  } = props;

  const rightIcon = rightIconProp ? <Icon {...rightIconProp} /> : null;

  return (
    <ButtonComponent
      ref={ref}
      px="6"
      py="3"
      borderRadius="lg"
      fontWeight="semibold"
      display={"flex"}
      gap="2"
      justifyContent={"center"}
      alignItems={"center"}
      disabled={disabled || isLoading}
      {...rest}
    >
      {leftIcon ? <Icon {...leftIcon} /> : null}
      {children}
      {isLoading ? <Loader /> : rightIcon}
    </ButtonComponent>
  );
});

export default Button;
